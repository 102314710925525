/*
    This component was in the old saatva-bits repo, and still exists in the remote bit-pr-workspace.
    It was not ported over to the new bit-pr-workspace repo in Github,because it's not used anywhere besides the PDP,
    specifically in the RequestSwatches component.
    If this component is needed in other apps, it should be ported to the bit-pr-workspace repo.
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FormCheckbox } from '@saatva-bits/pattern-library.components.form-checkbox'
import { FormError } from '@saatva-bits/pattern-library.components.form-error'
import styles from './FormCheckboxGroup.module.scss'

/**
 * Checkboxes are used for multiple choices list, unlike Radios which are used for mutually exclusive choices.
 * Each checkbox works independently from other checkboxes in the list, therefore checking an additional box should not affect any other selections.
 *
 * Users can select zero, one, or any number of items (and requirements can be placed on that number).
 */
const FormCheckboxGroup = ({
    className,
    checkboxes,
    name,
    errorMessage,
    required = 0,
    showError,
    onSelectionUpdate = () => {},
    ...other
}) => {
    const [state, setState] = useState({
        isValid: true,
        lastToggled: null,
        selectedValues: [],
    })

    useEffect(() => {
        onSelectionUpdate({
            selectedValues: state.selectedValues,
            lastToggled: state.value,
            isValid: state.isValid,
        })
    }, [state.selectedValues, state.isValid])

    const handleInputChange = (e) => {
        const target = e.target ? e.target : e
        const value = target.value

        if (!target.checked) {
            let selectedArr = [...state.selectedValues]

            const index = selectedArr.indexOf(target.value)
            selectedArr.splice(index, 1)

            setState(
                {
                    selectedValues: selectedArr,
                    value: value,
                    isValid: selectedArr.length >= required,
                }
            )
        } else {
            setState(
                (prevState) => ({
                    selectedValues: [...prevState.selectedValues, value],
                    value: value,
                    isValid: prevState.selectedValues.length + 1 >= required,
                })
            )
        }
    }

    const containerClasses = classNames(styles.container, className)

    const defaultMessage = `Please select at least ${required} option${
        required !== 1 ? 's' : ''
    }`

    return (
        <div className={containerClasses} {...other}>
            {checkboxes.map((checkbox) => (
                <FormCheckbox
                    key={checkbox.value}
                    name={name}
                    label={checkbox.label}
                    value={checkbox.value}
                    id={checkbox.id}
                    onChange={handleInputChange}
                    checked={
                        state.selectedValues &&
                        state.selectedValues.indexOf(checkbox.value) !== -1
                    }
                >
                    {checkbox.children}
                </FormCheckbox>
            ))}
            {showError || !state.isValid && (
                <FormError errorMessage={errorMessage || defaultMessage} />
            )}
        </div>
    )
}

FormCheckboxGroup.propTypes = {
    /** Applies a custom class to the FormTextInput component's wrapping div. */
    className: PropTypes.string,
    /** Array of values to be showed as checkboxes. */
    checkboxes: PropTypes.arrayOf(
        PropTypes.shape({
            className: PropTypes.string,
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            children: PropTypes.node.isRequired,
            id: PropTypes.string.isRequired
        }).isRequired
    ).isRequired,
    /** Error message to be showed when selection is required. */
    errorMessage: PropTypes.string,
    /** Array of selected values. */
    initialValues: PropTypes.array,
    /** Checkboxe's name attribute. */
    name: PropTypes.string.isRequired,
    /** Prop that controls whether the input is a required field. */
    required: PropTypes.number,
    /** Handler to manage the checkbox selection. */
    onSelectionUpdate: PropTypes.func,
    /** Indicates if an error message should be showed. */
    showError: PropTypes.bool,
}

export default FormCheckboxGroup
