const runFetch = async (path, data, customOptions = {}) => {
    const defaultOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest' // must be set so that req.xhr returns as true
        }
    }

    if (data) {
        defaultOptions.body = JSON.stringify(data)
    }

    // merge custom options, custom override default options
    const options = {
        ...defaultOptions,
        ...customOptions
    }
    
    try {
        const response = await fetch(path, options)
        if (response.status === 200) {
            return response.json()
        } else {
            const result = await response.json()
            const error = new Error(result || response.statusText || response.status)
            error.response = response
            throw error
        }
    } catch (error) {
        console.log(error)
        // allow the error to be caught by the caller
        throw error
    }
}

export default runFetch
