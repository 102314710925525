import React, { useState, useRef } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Button } from '@saatva-bits/pattern-library.components.button'
import { Dropdown } from '@saatva-bits/pattern-library.components.dropdown'
import { FormTextInput } from '@saatva-bits/pattern-library.components.form-text-input'
import { FormCheckboxGroup } from '@/components/RequestSwatches/FormCheckboxGroup'

import copy from '@/components/RequestSwatches/RequestSwatchesConfig.json'
import options from '@/constants/state-select-options.json'

import submitSwatchRequest from '@/services/zendesk/submitSwatchRequest'
import { useFeatureFlag } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/launch-darkly.hooks'

const RequestSwatchesModal = ({
    formFields,
    setFormFields,
    swatches,
    isLeather,
    onModalClose
}) => {
    const [shippingState, setShippingState] = useState(null)
    const [showError, setShowError] = useState(false)
    const [selectedSwatches, setSelectedSwatches] = useState([])
    const [showSwatchDelayedMessage, setShowSwatchDelayedMessage] = useState(false)
    const [isSwatchValid, setIsSwatchValid] = useState(false)

    const { value: delayedSwatches } = useFeatureFlag('DELAYED_SWATCHES.EX-60')
    // delayedSwatches is a comma-separated list of swatch values, convert to array
    const delayedSwatchList = delayedSwatches ? delayedSwatches.split(',').map(value => value.trim()) : []


    const formRef = useRef(null)

    const {
        customerFirstName,
        customerLastName,
        customerEmail,
        customerAddress,
        customerAddressNumber,
        customerState,
        customerCity,
        customerZipcode,
        submitStatus
    } = formFields

    const submitRequest = (e) => {
        if (!isSwatchValid) {
            e.preventDefault()
            // hide the delayed message if we're showing an error message
            setShowSwatchDelayedMessage(false)
            setShowError(true)
            return false
        } else {
            e.preventDefault()
            const swatchData = {
                selectedSwatches,
                customerFirstName,
                customerLastName,
                customerEmail,
                customerAddress,
                customerAddressNumber,
                customerState,
                customerCity,
                customerZipcode
            }
            return submitSwatchRequest(swatchData).then(jsonResponse => {
                if (jsonResponse.success) {
                    setFormFields({
                        swatches: [],
                        customerFirstName: '',
                        customerLastName: '',
                        customerEmail: '',
                        customerAddress: '',
                        customerAddressNumber: '',
                        customerState: '',
                        customerCity: '',
                        customerZipcode: '',
                        submitStatus: 'success'
                    })
                } else {
                    setFormFields({
                        ...formFields,
                        submitStatus: 'error'
                    })
                }
            }).catch((error) => {
                console.log(error)
                setFormFields({
                    ...formFields,
                    submitStatus: 'error'
                })
            })
        }
    }

    const handleSelectedStateChange = (selectResponse) => {
        setShippingState(selectResponse)
        setFormFields({ ...formFields, customerState: selectResponse })
    }

    const selectSwatch = (e) => {
        setSelectedSwatches(e.selectedValues)
        setIsSwatchValid(e.isValid)
        setShowError(false)

        // if a selected swatch is in delayed swatch list, and there's no swatch selection error, show delay message below swatches
        const isSwatchDelayed = e.selectedValues.some((values) => delayedSwatchList.includes(values))
        const isSwatchValid = e.isValid
        setShowSwatchDelayedMessage(isSwatchValid && isSwatchDelayed)
    }
    // TODO: add prop for countries

    const regions = options.countries['US'].regions.map((region) => {
        return {
            text: region.label,
            value: region.value
        }
    })

    const formContainerClasses = classNames({
        'requestSwatchesModal__contentContainer': true,
        'requestSwatchesModal__contentContainer--hide': submitStatus !== 'unsubmitted'
    })

    const successContainerClasses = classNames({
        'requestSwatchesModal__formSuccessContainer': true,
        'requestSwatchesModal__formSuccessContainer--hide': submitStatus !== 'success'
    })

    const errorContainerClasses = classNames({
        'requestSwatchesModal__formErrorContainer': true,
        'requestSwatchesModal__formErrorContainer--hide': submitStatus !== 'error'
    })

    const submitButtonClasses = classNames({
        'requestSwatchesModal__submitBtnContainer': true,
        'requestSwatchesModal__submitBtnContainer--hide': submitStatus !== 'unsubmitted'
    })

    const swatchCheckboxes = []

    swatches.forEach((swatch, i) => {
        const children = (
            <>
                {/* using div to avoid FormCheckbox component's span styles with high specificity */}
                { delayedSwatchList.includes(swatch.value) && <div className="requestSwatchesModal__swatchesContainer__delayed">DELAYED</div> }
                <img className="requestSwatchesModal__swatchesContainer__images" src={swatch.image} alt={swatch.label}/>
            </>
        )

        swatchCheckboxes.push({
            id: ('swatch_' + i), // required for the label's htmlFor attribute
            label: swatch.label,
            value: swatch.value,
            // eslint-disable-next-line @next/next/no-img-element
            children: children })
    })

    const swatchClose = () => {
        if (formFields.submitStatus !== 'unsubmitted') {
            setTimeout(() => {
                onModalClose()
            }, 0)

            setTimeout(() => {
                setFormFields({ ...formFields, submitStatus: 'unsubmitted' })
            }, 0)
        }
    }

    return (
        <React.Fragment>
            <div className={successContainerClasses}>
                <div className="t-heading4 u-marginBottom--none">{copy.success.title}</div>
                {
                    isLeather
                        ? <p className="u-textCenter col col--md-10">{copy.success.leather.text}</p>
                        : <p className="u-textCenter col col--md-10">{copy.success.fabric.text}</p>
                }
                <Button className="btn btn--primary" role="button" kind="primary" onClick={() => swatchClose()}>{copy.continueShopping}</Button>
            </div>
            <div className={errorContainerClasses}>
                <p className="t-bodyLg">{copy.error.title}</p>
                <h4 className="t-heading2 u-marginBottom--none">{copy.error.text}</h4>
            </div>
            <form id="requestSwatchesForm" ref={formRef} onSubmit={submitRequest}>
                <div className={formContainerClasses}>
                    <div className="requestSwatchesModal__swatchesContainer">
                        <FormCheckboxGroup
                            name={'testCheckbox'}
                            checkboxes={swatchCheckboxes}
                            required={1}
                            showError={showError}
                            onSelectionUpdate={(e) => selectSwatch(e)} />
                    </div>
                    { showSwatchDelayedMessage && <div className="requestSwatchesModal__backorder t-italic">*Selected swatch sample will be delayed in shipping due to a backorder.</div> }
                    <div className="row">
                        <div className="col col--xs-12 col--md-6">
                            <FormTextInput
                                className="requestSwatchesModal__textInput requestSwatchesModal__textInput--name"
                                labelClassName="requestSwatchesModal__textInputLabel"
                                errorMessage="Please enter your name"
                                inputMode="text"
                                inputName="customerFirstName"
                                inputType="text"
                                isRequired
                                labelText="First Name"
                                initialValue={customerFirstName}
                                onTextChange={(e) => setFormFields({ ...formFields, customerFirstName: e.target.value })} />
                        </div>
                        <div className="col col--xs-12 col--md-6">
                            <FormTextInput
                                className="requestSwatchesModal__textInput requestSwatchesModal__textInput--name"
                                labelClassName="requestSwatchesModal__textInputLabel"
                                errorMessage="Please enter your name"
                                inputMode="text"
                                inputName="customerLastName"
                                inputType="text"
                                isRequired
                                labelText="Last Name"
                                initialValue={customerLastName}
                                onTextChange={(e) => setFormFields({ ...formFields, customerLastName: e.target.value })} />
                        </div>
                        <div className="col col--xs-12 col--md-12">
                            <FormTextInput
                                className="requestSwatchesModal__textInput"
                                labelClassName="requestSwatchesModal__textInputLabel"
                                errorMessage="Please enter a valid email address"
                                inputMode="email"
                                inputName="customerEmail"
                                inputType="email"
                                isRequired
                                labelText="Enter Email"
                                initialValue={customerEmail}
                                onTextChange={(e) => setFormFields({ ...formFields, customerEmail: e.target.value })}
                                pattern="^([a-z0-9_.!#$%&'*+/=?^_`{|}~-]+)@([a-z0-9.!#$%&'*+/=?^_`{|}~-]+)\.([a-z]{2,4})$" />
                        </div>
                        <div className="col col--xs-12 col--md-8">
                            <FormTextInput
                                className="requestSwatchesModal__textInput"
                                labelClassName="requestSwatchesModal__textInputLabel"
                                errorMessage="Please enter your address"
                                inputMode="text"
                                inputName="customerAddress"
                                inputType="text"
                                isRequired
                                labelText="Address"
                                initialValue={customerAddress}
                                onTextChange={(e) => setFormFields({ ...formFields, customerAddress: e.target.value })} />
                        </div>
                        <div className="col col--xs-12 col--md-4">
                            <FormTextInput
                                className="requestSwatchesModal__textInput"
                                labelClassName="requestSwatchesModal__textInputLabel"
                                errorMessage="Please enter your address"
                                inputMode="text"
                                inputName="customerAddressNumber"
                                inputType="text"
                                labelText="Apt or Suite #"
                                initialValue={customerAddressNumber}
                                onTextChange={(e) => setFormFields({ ...formFields, customerAddressNumber: e.target.value })} />
                        </div>
                        <div className="col col--xs-12 col--md-6">
                            <FormTextInput
                                className="requestSwatchesModal__textInput"
                                labelClassName="requestSwatchesModal__textInputLabel"
                                errorMessage="Please enter your city"
                                inputMode="text"
                                inputName="customerCity"
                                inputType="text"
                                isRequired
                                labelText="City"
                                initialValue={customerCity}
                                onTextChange={(e) => setFormFields({ ...formFields, customerCity: e.target.value })} />
                        </div>
                        <div className="col col--xs-6 col--md-3">
                            <Dropdown
                                canScroll={true}
                                id="state"
                                wrapperClassName='requestSwatchesModal__dropdown'
                                toggleClassName='dropdown__toggle'
                                selectedValue={shippingState}
                                defaultText="State"
                                itemsData={regions}
                                iconAlt="State"
                                selectProps={
                                    {
                                        name: 'state',
                                        required: true
                                    }
                                }
                                onChange={(event) => handleSelectedStateChange(event.value)}
                                // errorMessage not yet supported by Dropdown component
                                // error message not present in coresite-node implementation
                            />
                        </div>
                        <div className="col col--xs-6 col--md-3">
                            <FormTextInput
                                className="requestSwatchesModal__textInput"
                                labelClassName="requestSwatchesModal__textInputLabel"
                                errorMessage="Please enter your zipcode"
                                inputMode="text"
                                inputName="customerZipcode"
                                inputType="text"
                                isRequired
                                labelText="Zipcode"
                                initialValue={customerZipcode}
                                onTextChange={(e) => setFormFields({ ...formFields, customerZipcode: e.target.value })} />
                        </div>
                    </div>
                </div>
                <div className={submitButtonClasses}>
                    <Button className="requestSwatchesModal__submitBtn" kind="primary" role="button" type="submit">{copy.submit}</Button>
                </div>
            </form>
        </React.Fragment>
    )
}

RequestSwatchesModal.propTypes = {
    formFields: PropTypes.object.isRequired,
    setFormFields: PropTypes.func.isRequired,
    swatches: PropTypes.array.isRequired,
    isLeather: PropTypes.bool,
    onModalClose: PropTypes.func.isRequired
}

export default RequestSwatchesModal
