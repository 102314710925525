import React, { useContext, useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Button } from '@saatva-bits/pattern-library.components.button'

import { Modal } from '@saatva-bits/pattern-library.components.modal'
import { Global } from '@/contexts'

import { useProductData } from '@saatva-bits/pattern-library.modules.selection'
import RequestSwatchesModal from '@/components/RequestSwatches/RequestSwatchesModal'
import { Icon } from '@saatva-bits/pattern-library.components.icon'

const IMGIX_URL = process.env.NEXT_PUBLIC_IMGIX_URL

const RequestSwatches = ({
    children,
    className,
    modalTitle,
    isLeather,
    isLink,
    modalId = 'requestSwatchesModal'
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false)

    const { urlProductCode: productCode } = useContext(Global.Context)
    const { options } = useProductData(productCode)
    const fabricValues = options.fabric && options.fabric.values ? options.fabric.values : []

    const swatches = fabricValues.map((fabric) => {
        const formattedFabricCode = fabric.code.toLowerCase().replaceAll(' ', '-')
        const imageUrl = `https://${IMGIX_URL}/swatches/${formattedFabricCode}/${formattedFabricCode}-1-1.jpg`

        return {
            label: fabric.label,
            value: fabric.code,
            image: imageUrl
        }
    })

    const initialState = {
        swatches: swatches,
        customerName: '',
        customerEmail: '',
        customerOrderNumber: '',
        reviewTitle: '',
        reviewText: '',
        submitStatus: 'unsubmitted'
    }

    const [formFields, setFormFields] = useState(initialState)

    // Scroll to the top of the modal when the form is submitted to show the success or error message
    useEffect(() => {
        if (typeof document !== 'undefined') {
            const modal = document.getElementById(modalId)
            if (modal) {
                modal.scrollTop = 0
            }
        }
    }, [formFields.submitStatus])

    const onModalClose = () => {
        setIsModalVisible(false)
    }

    const requestSwatchModalProps = {
        formFields,
        setFormFields,
        swatches,
        isLeather,
        onModalClose
    }

    const buttonClassNames = classNames(className, {
        'requestSwatches__requestSwatchesLinkContainer': isLink
    })

    return (
        <>
            <Button
                className={buttonClassNames}
                kind="primary"
                type="button"
                role="button"
                onClick={() => setIsModalVisible(true)}
            >
                {children}
            </Button>
            <Modal
                type="popup"
                closeButtonContent={
                    <Icon
                        className={'requestModal_CloseBtn'}
                        name="close"
                        alt="Close modal"
                        description="Close Modal"
                        titleId="close" />
                }
                isModalVisible={isModalVisible}
                onModalClose={onModalClose}
                modalTitle={modalTitle}
                modalLabel="modalLabel"
                id={modalId} // used for scroll to top functionality
            >
                <RequestSwatchesModal {...requestSwatchModalProps} />
            </Modal>
        </>
    )
}

RequestSwatches.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    modalTitle: PropTypes.string,
    isLeather: PropTypes.bool,
    isLink: PropTypes.bool,
    modalId: PropTypes.string
}

export default RequestSwatches
