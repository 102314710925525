import runFetch from '@/utils/runFetch'

const submitSwatchRequest = async ({ selectedSwatches, customerFirstName, customerLastName, customerEmail, customerAddress, customerAddressNumber = '', customerState, customerCity, customerZipcode }) => {
    const body = {
        selectedSwatches,
        customerFirstName,
        customerLastName,
        customerEmail,
        customerAddress,
        customerAddressNumber,
        customerState,
        customerCity,
        customerZipcode
    }

    const customOptions = {
        method: 'POST'
    }

    return await runFetch(`https://www.${process.env.NEXT_PUBLIC_STOREFRONT_CORESITE_NODE_DOMAIN}/service/zendesk/swatch`, body, customOptions)
}

export default submitSwatchRequest
